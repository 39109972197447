// ==============================
// Custom style
// 自定义样式
// ==============================
.home[posts] .summary .content{line-height:2em;}
.single .content p{line-height:1.75em;}
//.header-wrapper{max-width:1200px;margin:0 auto}
.home[posts] .summary .post-footer a, 
.home[posts] .summary .post-footer a::before, 
.home[posts] .summary .post-footer a::after{padding:.1em 1.5em; border-radius:5px;background:rgba(0,0,0,.05)}
.home[posts] .summary .post-footer .post-tags a, 
.home[posts] .summary .post-footer .post-tags a::before, 
.home[posts] .summary .post-footer .post-tags a::after{padding:3px;border:none;background:none}
.home[posts] .summary .single-title{line-height:2em;}
.single .post-meta a, 
.single .post-meta a::before, 
.single .post-meta a::after{color:#777;}
.home[posts] .summary .content{color:#999}