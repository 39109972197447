// ==============================
// Override Variables
// 覆盖变量
// ==============================
$global-font-color: #3f3f3f;
$global-link-color: #3f3f3f;
$header-hover-color: #3f3f3f;

$single-link-color: #3f51b5;
$global-link-hover-color: #3f51b5;
$pagination-link-color: #3f51b5;
